import { useTranslation } from 'react-i18next';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';
import { useScrollToTop } from 'hooks/utils';

import { PolicyContainer, PolicyHeader, PolicyText } from './RefundPolicy.styles';

const RefundPolicy = ({ propsContextRoot }) => {
  const { storeInfo } = propsContextRoot;
  const { t } = useTranslation(['pageRefundPolicy']);

  useScrollToTop();

  return (
    <PolicyContainer>
      <PolicyHeader>{t('pageRefundPolicy:title')}</PolicyHeader>
      <PolicyText>{storeInfo.refundPolicy || t('pageRefundPolicy:no-policy')}</PolicyText>
    </PolicyContainer>
  );
};

export default withContextRoot(RefundPolicy);
