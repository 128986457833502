import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import RequiredAsterisk from 'components/RequiredAsterisk/RequiredAsterisk';

import { Root } from './FormLabel.styles';

const FormLabel = ({ hasAsterisk = false, tooltipMessage = '', children, ...props }) => {
  return (
    <Root {...props}>
      {hasAsterisk ? <RequiredAsterisk /> : ''}
      {children}{' '}
      {tooltipMessage && (
        <Tooltip title={tooltipMessage}>
          <QuestionCircleFilled />
        </Tooltip>
      )}
    </Root>
  );
};

FormLabel.propTypes = {
  hasAsterisk: PropTypes.bool
};

export default FormLabel;
