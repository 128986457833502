import { useMemo } from 'react';

import api from 'apis/apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

/* ================================================ Exported Functions ================================================ */
export const useFetchConstant = (name, { valueKey = 'code', labelKey = 'label', extraCustomKey = '', query = {}, postProcessFunc } = {}) => {
  const getConstant = async () => {
    return api.get(`/constants/${name}`, { params: query });
  };

  const { isLoading, data: constantObject, ...returnedParams } = useCustomQuery(`constant${name}${extraCustomKey}`, [name, query], getConstant, {
    postProcessFunc,
    prefixErrorMessage: `Error while getting ${name}.\n`,
    staleTime: Infinity
  });

  const shouldConstructOption = !isLoading && !!constantObject;

  const options = useMemo(() => constructOptions(shouldConstructOption, constantObject, valueKey, labelKey), [
    shouldConstructOption,
    constantObject,
    valueKey,
    labelKey
  ]);

  return { isLoading, options, data: constantObject, ...returnedParams };
};

/* ================================================ Local Functions ================================================ */
const constructOptions = (shouldConstruct, constantObject, valueKey, labelKey) => {
  if (!shouldConstruct) {
    return [];
  }

  if (shouldConstruct) {
    const options = Object.values(constantObject);
    return options.map(option => ({
      ...option,
      value: option[valueKey],
      label: option[labelKey]
    }));
  }
};
