import PropTyes from 'prop-types';

import { StyledAvatar } from './ProfileAvatar.styles';

const getInitalsFromFullName = fallbackName => {
  const nameSeperations = fallbackName ? fallbackName.split(' ') : [];

  if (nameSeperations.length > 0) {
    const firstInitial = nameSeperations[0] ? nameSeperations[0].charAt(0).toUpperCase() : '';
    const secondInitial = nameSeperations[1] ? nameSeperations[1].charAt(0).toUpperCase() : '';
    return `${firstInitial}${secondInitial}`.toLowerCase();
  }

  return 'U';
};

const ProfileAvatar = ({ size, src, fallbackName, onClick, isSquare = false, isHideShadow = false }) => {
  return (
    <StyledAvatar shape={isSquare ? 'square' : undefined} isHideShadow={isHideShadow} size={size} src={src} onClick={onClick}>
      {getInitalsFromFullName(fallbackName)}
    </StyledAvatar>
  );
};

ProfileAvatar.propTypes = {
  size: PropTyes.number,

  src: PropTyes.string,
  fallbackName: PropTyes.string,

  onClick: PropTyes.func,

  isSquare: PropTyes.bool,
  isHideShadow: PropTyes.bool
};

export default ProfileAvatar;
