import { useCustomQuery } from 'hooks/reactQuery';
import api from './apiHelper';

const formatProductItemsPayload = productItems => {
  return productItems.map(productItem => {
    return {
      productId: productItem.productId,
      varianceId: productItem.varianceId,
      quantity: productItem.quantity
    };
  });
};

export const getCustomerPendingOrdersWithContact = async contact => {
  const { countryCode, contactNumber } = contact;
  return api.get(`/order/customer/pending`, { params: { contactCountryCode: countryCode, contactNumber } });
};

export const useGetCustomerPendingOrders = (customerId, onError) => {
  const getCustomerPendingOrders = async () => {
    return api.get(`/order/customer/pending`, { params: { customerId } });
  };

  return useCustomQuery('customerPendingOrders', [customerId], getCustomerPendingOrders, {
    shouldDefaultEmptyObject: false,
    onError,
    enabled: !!customerId
  });
};

export const postGetPendingOrder = async (orderNumber, accessCode) => {
  const path = '/order/get-pending';
  const payload = { orderNumber, accessCode };

  return api.post(path, payload);
};

export const postCartValidate = async productItems => {
  const path = '/order/validate';
  const payload = { productItems: formatProductItemsPayload(productItems) };

  return api.post(path, payload);
};

export const postCheckoutCart = async (customerInfo, productItems, orderToCombine) => {
  const path = '/order';
  const payload = {
    customer: customerInfo,
    products: formatProductItemsPayload(productItems),
    orderToCombine: orderToCombine ? { orderNumber: orderToCombine.orderNumber, accessCode: orderToCombine.accessCode } : undefined
  };

  return api.post(path, payload);
};
