import { useTranslation } from 'react-i18next';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';
import { useScrollToTop } from 'hooks/utils';

import { TermsContainer, TermsHeader, TermsText } from './TermsOfService.styles';

const TermsOfService = ({ propsContextRoot }) => {
  const { storeInfo } = propsContextRoot;
  const { t } = useTranslation(['pageTermsOfService']);

  useScrollToTop();

  return (
    <TermsContainer>
      <TermsHeader>{t('pageTermsOfService:title')}</TermsHeader>
      <TermsText>{storeInfo.termsOfService || t('pageTermsOfService:no-terms-of-service')}</TermsText>
    </TermsContainer>
  );
};

export default withContextRoot(TermsOfService);
