import Input from 'components/Input/Input';
import Selection from 'components/Selection/Selection';

import styled from '@emotion/styled';

export const StyledSelection = styled(Selection)`
  height: 100%;

  .ant-select-selector {
    border-radius: 0 !important;
  }
`;

export const StyledInput = styled(Input)`
  height: 100%;
  border-radius: 0;
`;
