import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import Contact from '../Contact';

const FormContact = ({
  name,
  label,
  options,

  isDisabled,

  extraRules,
  requiredErrorMessage,

  onChange,

  tooltipMessage,

  extraPropsInput,
  extraPropsSelection
}) => {
  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} extraRules={extraRules} tooltipMessage={tooltipMessage}>
      <Contact
        isDisabled={isDisabled}
        options={options}
        onChange={onChange}
        extraPropsInput={extraPropsInput}
        extraPropsSelection={extraPropsSelection}
      />
    </FormItem>
  );
};

FormContact.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,

  extraRules: PropTypes.array,
  requiredErrorMessage: PropTypes.string,

  isDisabled: PropTypes.bool,

  tooltipMessage: PropTypes.string,

  onChange: PropTypes.func,

  extraPropsInput: PropTypes.object,
  extraPropsSelection: PropTypes.object
};

export default FormContact;
