import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const useGetProducts = (query = {}, onError) => {
  const getProducts = async (key, query) => {
    return api.get(`/product`, {
      params: { ...query }
    });
  };
  return useCustomPaginatedQuery('products', { ...query }, getProducts, {
    onError
  });
};

export const useGetFeaturedProducts = (query = {}, onError) => {
  const getFeaturedProducts = async () => {
    return api.get(`/product/featured`, {
      params: { ...query }
    });
  };
  return useCustomQuery('featuredProducts', [query], getFeaturedProducts, {
    onError
  });
};

export const useGetLiveSellingProducts = (postId, query = {}, refetchInterval, onError) => {
  const getLiveSellingProducts = async () => {
    return api.get(`/product/selling`, {
      params: { ...query }
    });
  };

  return useCustomQuery('liveSellingProducts', [postId, query], getLiveSellingProducts, {
    onError,
    refetchInterval,
    enabled: !!postId
  });
};

export const useGetProductDetails = (productId, query = {}, onError) => {
  const getProductDetails = async () => {
    return api.get(`/product/${productId}`, {
      params: { ...query }
    });
  };

  return useCustomQuery('productDetails', [productId, query], getProductDetails, {
    onError,
    enabled: !!productId
  });
};
