import { useTranslation } from 'react-i18next';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';
import { useScrollToTop } from 'hooks/utils';

import { PolicyContainer, PolicyHeader, PolicyText } from './PrivacyPolicy.styles';

const PrivacyPolicy = ({ propsContextRoot }) => {
  const { storeInfo } = propsContextRoot;
  const { t } = useTranslation(['pagePrivacyPolicy']);

  useScrollToTop();

  return (
    <PolicyContainer>
      <PolicyHeader>{t('pagePrivacyPolicy:title')}</PolicyHeader>
      <PolicyText>{storeInfo.privacyPolicy || t('pagePrivacyPolicy:no-policy')}</PolicyText>
    </PolicyContainer>
  );
};

export default withContextRoot(PrivacyPolicy);
