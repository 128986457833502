import qs from 'query-string';
import { message } from 'antd';

import { EMAIL_ADDRESS_REGEX } from 'utils/regex';

/* ================================================ Exported Functions ================================================ */
// ================================================ Checking
export const checkHasValue = (value, shouldCheckEmptyString = false) => {
  const hasValue = value !== undefined && value !== null;

  if (hasValue && shouldCheckEmptyString) {
    return value !== '';
  }

  return hasValue;
};

export const checkIsObject = object => {
  return typeof object === 'object';
};

export const checkIsObjectNotEmpty = object => {
  return guard(() => Object.keys(object).length > 0, false);
};

export const checkIsString = string => {
  return typeof string === 'string';
};

export const checkIsArray = array => {
  return Array.isArray(array);
};

export const checkIsArrayNotEmpty = array => {
  return checkIsArray(array) && array.length > 0;
};

export const checkIsEmail = value => EMAIL_ADDRESS_REGEX.test(value);

export const checkIsIdSame = (id, idToCompare) => {
  return String(id) === String(idToCompare);
};

export const checkIsStringExist = (originalString, stringToCheck) => {
  return guard(() => originalString.toLowerCase().indexOf(stringToCheck) !== -1, false);
};

// ================================================ Format String
export const getSingularOrPluralLabel = (amount, singularLabel, { pluralLabel, labelOnly } = {}) => {
  const returnLabel = amount === 1 ? singularLabel : pluralLabel || `${singularLabel}s`;
  const returnAmount = labelOnly ? '' : amount;

  return `${returnAmount} ${returnLabel}`.trim();
};

export const constructDisplayPrice = (feeAmount, shouldPrefix = true, defaultValue) => {
  const displayFee = !!feeAmount || feeAmount === 0 ? String(numberWithCommas(feeAmount)) : defaultValue;
  const displayFeeWithPrefix = shouldPrefix ? `RM ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};

export const numberWithCommas = num => {
  let formattedNum = num;
  if (!isNaN(formattedNum)) {
    formattedNum = Number(formattedNum).toFixed(2);
  } else {
    formattedNum = Number(0).toFixed(2);
  }
  return formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addProtocolToUrl = url => (url.match(/^https?:/) ? url : '//' + url);

// ================================================ Array
export const flatArray = (initialArray, flatLevel = 1) => {
  let flattenedArray = [...initialArray];

  for (let i = 0; i < flatLevel; i++) {
    flattenedArray = flatOneLevelArray(flattenedArray);
  }

  return flattenedArray;
};

export const removeItemFromArray = (array, index) => {
  let formattedArray = [...array];
  formattedArray[index] = undefined;
  formattedArray = formattedArray.filter(item => item);
  return formattedArray;
};

// ================================================ Object
export const removeObjectEmptyKey = object => {
  return Object.entries(object).reduce((result, [objectKey, objectValue]) => {
    if (objectValue === undefined) {
      delete result[objectKey];
    }

    return result;
  }, object);
};

export const getDataFromObject = (dataIndex, object) => {
  const dataKeys = dataIndex.split('.');
  const lastDataKey = dataKeys[dataKeys.length - 1];

  const data = guard(() => dataKeys.reduce((resultObject, currentKey) => resultObject[currentKey], object));

  return { data, lastDataKey };
};

export const cloneNewObject = object => {
  return JSON.parse(JSON.stringify(object));
};

// ================================================ URL Query
export const constructQueryAsObject = queryString => {
  return qs.parse(queryString);
};

export const constructQueryString = queryObject => {
  return qs.stringify(queryObject);
};

// ================================================ Format String
export const encodeImageUrl = imageUrl => {
  const imageUrlPaths = imageUrl.split('/');
  const imageUrlFileName = imageUrlPaths[imageUrlPaths.length - 1];
  return imageUrl.replace(imageUrlFileName, encodeURIComponent(imageUrlFileName));
};

// ================================================ Special
export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

export const errorHandler = (error, { fallbackValue, prefixErrorMessage = '' } = {}) => {
  const baseErrorMessage = error.message || 'Something went wrong while retriving data, please contact our technical support';
  const errorMessage = `${prefixErrorMessage}${baseErrorMessage}`;
  message.error({ key: errorMessage, content: errorMessage });

  return fallbackValue;
};

export const errorHandlerForm = (formErrors, { shouldSilenceError = true, fallbackValue, prefixErrorMessage = '' } = {}) => {
  if (shouldSilenceError) {
    return;
  }

  return formErrors.errorFields.map(errorField => {
    return errorField.errors.map(error => errorHandler({ message: error }, { fallbackValue, prefixErrorMessage }));
  });
};

// ================================================ Specific for Module
// ------------------------------------------------ Unit
export const constructUnitAddressInString = ({ block, floor, unitNo }, isShortAddress) => {
  const hasAddress = !!block && !!floor && !!unitNo;
  const address = isShortAddress ? `${block}-${floor}-${unitNo}` : `Block ${block}, Unit ${floor}-${unitNo}`;

  return hasAddress ? address : undefined;
};

// ------------------------------------------------ Contact
export const constructContactInString = ({ countryCode, contactNumber }) => {
  return `${countryCode} ${contactNumber}`;
};

/* ================================================ Local Functions ================================================ */
// ================================================ Array
const flatOneLevelArray = initialArray => {
  const flattenedArray = [];

  for (let i = 0; i < initialArray.length; i++) {
    const current = initialArray[i];

    if (checkIsArray(current)) {
      for (let j = 0; j < current.length; j++) {
        flattenedArray.push(current[j]);
      }
    }
  }

  return flattenedArray;
};
