import styled from '@emotion/styled';

export const LanguageSwitcherOption = styled.span`
  cursor: pointer;
  font-weight: ${props => (props.selected ? props.theme.fontWeight.bold : props.theme.fontWeight.regular)};
  color: ${props => (props.selected ? props.theme.color.primary : props.theme.color.black)};
  margin-right: ${props => props.right || 0}px;
  margin-left: ${props => props.left || 0}px;

  &:hover {
    color: ${props => props.theme.color.primary};
  }
`;
