import { useTranslation } from 'react-i18next';

import { LanguageSwitcherOption } from './LanguageSwitcher.styles';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const getIsLanguageSelected = language => i18n && i18n.language === language;

  return (
    <span>
      <LanguageSwitcherOption right="8" selected={getIsLanguageSelected('en')} onClick={() => i18n.changeLanguage('en')}>
        EN
      </LanguageSwitcherOption>{' '}
      |{' '}
      <LanguageSwitcherOption left="8" selected={getIsLanguageSelected('zh')} onClick={() => i18n.changeLanguage('zh')}>
        中文
      </LanguageSwitcherOption>
    </span>
  );
};

export default LanguageSwitcher;
