import { cloneElement } from 'react';
import PropTypes from 'prop-types';

import FormLabel from 'components/FormLabel/FormLabel';

import { StyledFormItem } from './FormItem.styles';

const addRules = (requiredErrorMessage, extraRules) => {
  const rules = [];

  if (!!requiredErrorMessage) {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }

  return [...rules, ...extraRules];
};

const ChildrenWrapper = ({ children, onChange, ...formProps }) => {
  return <>{cloneElement(children, { ...formProps, updateFormValue: onChange })}</>;
};

const FormItem = ({
  className,
  name,
  label,

  requiredErrorMessage,
  extraRules = [],

  noStyle,
  isFormItem = true,
  hasAsterisk = false,
  tooltipMessage,

  children,

  ...props
}) => {
  const rules = addRules(requiredErrorMessage, extraRules);

  return (
    <>
      {!!label && (
        <FormLabel hasAsterisk={hasAsterisk || !!requiredErrorMessage} tooltipMessage={tooltipMessage}>
          {label}
        </FormLabel>
      )}
      <StyledFormItem className={className} name={name} colon={false} rules={rules} {...props}>
        {isFormItem ? <ChildrenWrapper>{children}</ChildrenWrapper> : children}
      </StyledFormItem>
    </>
  );
};

FormItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,

  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,

  noStyle: PropTypes.bool,
  isFormItem: PropTypes.bool,
  hasAsterisk: PropTypes.bool,
  tooltipMessage: PropTypes.string,

  children: PropTypes.node.isRequired
};

export default FormItem;
