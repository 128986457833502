import { ConfigProvider } from 'antd';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { QueryClientProvider } from './reactQuery/queryClient';

import { ContextRootProvider } from 'contexts/ContextRoot/ContextRoot';
import { ContextCartProvider } from 'contexts/ContextCart/ContextCart';
import LayoutMain from 'layouts/LayoutMain/LayoutMain';
import Cart from 'pages/Cart/Cart';
import Home from 'pages/Home/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from 'pages/RefundPolicy/RefundPolicy';
import TermsOfService from 'pages/TermsOfService/TermsOfService';

import NotFound404 from 'pages/404/NotFound404';

import {
  getHomeRoute,
  getProductDetailsRoute,
  getCartRoute,
  getPrivacyPolicyRoute,
  getRefundPolicyRoute,
  getTermsOfServiceRoute,
  get404Route
} from 'utils/routes';

import reportWebVitals from './reportWebVitals';
import { useLoadAllTranslationFiles } from './i18n';
import './index.css';

const homeRoute = getHomeRoute();
const productRoute = getProductDetailsRoute();
const cartRoute = getCartRoute();
const privacyPolicyRoute = getPrivacyPolicyRoute();
const refundPolicyRoute = getRefundPolicyRoute();
const termsOfServiceRoute = getTermsOfServiceRoute();
const notFoundRoute = get404Route();

const Main = () => {
  useLoadAllTranslationFiles();

  return (
    <Router>
      <ContextRootProvider>
        <ContextCartProvider>
          <LayoutMain>
            <Routes>
              <Route path={homeRoute.path} element={<Home />} />
              <Route path={productRoute.path} element={<Home />} />
              <Route path={cartRoute.path} element={<Cart />} />
              <Route path={privacyPolicyRoute.path} element={<PrivacyPolicy />} />
              <Route path={refundPolicyRoute.path} element={<RefundPolicy />} />
              <Route path={termsOfServiceRoute.path} element={<TermsOfService />} />
              <Route path={notFoundRoute.path} element={<NotFound404 />} />
              <Route path="*" element={<Navigate to={homeRoute.path} />} />
            </Routes>
          </LayoutMain>
        </ContextCartProvider>
      </ContextRootProvider>
    </Router>
  );
};

ReactDOM.render(
  <StrictMode>
    <Suspense fallback="loading">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#FF7212',
            colorLink: '#FF7212',
            colorBorder: '#f0f0f0',
            borderRadius: 4
          },
          components: {
            Input: {
              controlHeight: 40,
              colorBorder: '#f0f0f0',
              colorBgBase: '#ffffff'
            },
            InputNumber: {
              colorBorder: '#f0f0f0'
            },
            Layout: {
              triggerBg: '#001b69'
            },
            Button: {
              controlHeight: 40
            },
            Select: {
              controlHeight: 40,
              colorBorder: '#f0f0f0'
            }
          }
        }}
      >
        <HelmetProvider>
          <QueryClientProvider>
            <Helmet>
              <title>Shop with us</title>
            </Helmet>
            <Main />
          </QueryClientProvider>
        </HelmetProvider>
      </ConfigProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
