import { Badge, Button, Col, ConfigProvider, Divider, Layout, Row, Skeleton, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaFacebookSquare, FaInstagram, FaTelegramPlane, FaTiktok } from 'react-icons/fa';
import { MdLocationOn, MdOutlineWhatsapp } from 'react-icons/md';

import { withContextCart } from 'contexts/ContextCart/ContextCart';
import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import ProfileAvatar from 'components/_local/ProfileAvatar/ProfileAvatar';

import { useNav } from 'utils/routes';
import { constructCheckoutURL, getCartRoute, getHomeRoute, getPrivacyPolicyRoute, getRefundPolicyRoute, getTermsOfServiceRoute } from 'utils/routes';
import { addProtocolToUrl } from 'utils/general';

import {
  CartIcon,
  StyledLayoutContainer,
  StyledContent,
  StyledContentBanner,
  StyledHeader,
  StyledFooter,
  StyledFooterSocialMediaContainer,
  StyledFooterCopyRightText,
  StyledFooterPolicyContainer,
  StyledFooterLink
} from './LayoutMain.styles';
import Logo from 'images/logo.png';

const homeRoute = getHomeRoute();
const cartRoute = getCartRoute();
const privacyPolicyRoute = getPrivacyPolicyRoute();
const refundPolicyRoute = getRefundPolicyRoute();
const termsOfServiceRoute = getTermsOfServiceRoute();

const LayoutMain = ({ children, isOnLive = true, nextLive, propsContextRoot, propsContextCart }) => {
  const { isLoading: isLoadingContextRoot, storeInfo, isProLayoutView } = propsContextRoot;
  const { productItems, customerPendingOrders } = propsContextCart;
  const {
    name,
    customDomainLogoUrl,
    socialMediaFb,
    socialMediaIg,
    socialMediaTg,
    socialMediaWa,
    socialMediaMap,
    socialMediaTt,
    termsOfService,
    privacyPolicy,
    refundPolicy
  } = storeInfo;

  const { t } = useTranslation(['layoutMain']);
  const nav = useNav();

  const totalCartProductItems = productItems && productItems.length;
  const hasPendingOrdersBanner = !!customerPendingOrders && customerPendingOrders.length > 0;

  return (
    <Layout style={{ backgroundColor: '#FAFAFA' }}>
      <ConfigProvider
        theme={{ token: isProLayoutView && storeInfo.themeColor ? { colorPrimary: storeInfo.themeColor, colorLink: storeInfo.themeColor } : {} }}
      >
        <StyledLayoutContainer>
          <StyledHeader>
            <Row justify="space-between" align="middle">
              <Col>
                {!isLoadingContextRoot ? (
                  <ProfileAvatar src={customDomainLogoUrl || Logo} size={48} isSquare isHideShadow onClick={() => nav(homeRoute.path)} />
                ) : null}
              </Col>
              <Col>
                <Space>
                  <LanguageSwitcher />
                  <Badge count={totalCartProductItems} overflowCount={10} offset={[totalCartProductItems < 10 ? -4 : 4, 8]}>
                    <Button type="text" icon={<CartIcon />} onClick={() => nav(cartRoute.path)} />
                  </Badge>
                </Space>
              </Col>
            </Row>
          </StyledHeader>
          {isLoadingContextRoot ? (
            <Skeleton active />
          ) : (
            <>
              {hasPendingOrdersBanner &&
                customerPendingOrders.map(pendingOrder => {
                  return (
                    <StyledContentBanner
                      key={pendingOrder.orderNumber}
                      message={
                        <span>
                          <Tag color="#ffba00">#{pendingOrder.orderNumber}</Tag>
                          {t('layoutMain:notification-banner-pending-order-msg')}
                        </span>
                      }
                      banner
                      closable
                      showIcon={false}
                      action={
                        <Button
                          size="small"
                          type="text"
                          onClick={() => window.open(constructCheckoutURL(pendingOrder.orderNumber, pendingOrder.accessCode))}
                        >
                          {t('layoutMain:notification-banner-pending-order-action-text')}
                        </Button>
                      }
                    />
                  );
                })}
              <StyledContent>{children}</StyledContent>
            </>
          )}
          <StyledFooter>
            <StyledFooterSocialMediaContainer>
              {socialMediaFb && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaFb), '_blank')}>
                  <FaFacebookSquare size="16px" color="white" />
                </Button>
              )}
              {socialMediaIg && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaIg), '_blank')}>
                  <FaInstagram size="16px" color="white" />
                </Button>
              )}
              {socialMediaTg && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaTg), '_blank')}>
                  <FaTelegramPlane size="16px" color="white" />
                </Button>
              )}
              {socialMediaWa && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaWa), '_blank')}>
                  <MdOutlineWhatsapp size="16px" color="white" />
                </Button>
              )}
              {socialMediaMap && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaMap), '_blank')}>
                  <MdLocationOn size="16px" color="white" />
                </Button>
              )}
              {socialMediaTt && (
                <Button type="text" size="small" onClick={() => window.open(addProtocolToUrl(socialMediaTt), '_blank')}>
                  <FaTiktok size="16px" color="white" />
                </Button>
              )}
            </StyledFooterSocialMediaContainer>
            <Divider style={{ borderColor: 'white', margin: '12px 0' }} />
            <StyledFooterCopyRightText>
              © {new Date().getFullYear()} {name} • Powered by Ulive
            </StyledFooterCopyRightText>
            <StyledFooterPolicyContainer>
              {termsOfService && <StyledFooterLink to={termsOfServiceRoute.path}>{t('layoutMain:footer-terms-of-service')}</StyledFooterLink>}
              {termsOfService && (privacyPolicy || refundPolicy) ? '|' : ''}
              {privacyPolicy && <StyledFooterLink to={privacyPolicyRoute.path}>{t('layoutMain:footer-privacy-policy')}</StyledFooterLink>}
              {privacyPolicy && refundPolicy ? '|' : ''}
              {refundPolicy && <StyledFooterLink to={refundPolicyRoute.path}>{t('layoutMain:footer-refund-policy')}</StyledFooterLink>}
            </StyledFooterPolicyContainer>
          </StyledFooter>
        </StyledLayoutContainer>
      </ConfigProvider>
    </Layout>
  );
};

export default withContextRoot(withContextCart(LayoutMain));
