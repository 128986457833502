import moment from 'moment';

import {
  DAYS_OF_WEEK,
  DATE_FORMAT,
  MONTH_FORMAT,
  TIME_FORMAT,
  DATE_FORMAT_STRING,
  DATE_FORMAT_DATE_OBJ,
  constructConstantLabel
} from 'utils/constants';
import { checkIsString } from 'utils/general';

// ======================================== General Functions
export const getCurrentMoment = (date, format) => (!!date ? moment(date, format) : moment());
export const getCurrentMonth = () => moment().startOf('month');
export const getStartOfDayMoment = date => getCurrentMoment(date).startOf('day');

export const addMoment = (date, amount, dateUnit, { returnFormat = false, shouldResetToStartOfDate = false } = {}) => {
  const dateMoment = moment(date);
  const updatedDateMoment = dateMoment.add(amount, dateUnit.toLowerCase());
  shouldResetToStartOfDate && updatedDateMoment.startOf('day');

  if (returnFormat === DATE_FORMAT_STRING) {
    return formatToDateString(updatedDateMoment);
  } else if (returnFormat === DATE_FORMAT_DATE_OBJ) {
    return updatedDateMoment.toDate();
  } else {
    return updatedDateMoment;
  }
};

export const formatToTimeMoment = date => {
  return getCurrentMoment(date, TIME_FORMAT);
};

// ======================================== Get Date in String
export const formatToDateString = (date, format = DATE_FORMAT) => {
  return getCurrentMoment(date).format(format);
};

export const formatToMonthString = date => {
  return getCurrentMoment(date).format(MONTH_FORMAT);
};

export const formatToTimeString = timeMoment => {
  return getCurrentMoment(timeMoment).format(TIME_FORMAT);
};

// ======================================== Week Related Functions
export const getDayOfWeekMoment = (date, dayofWeekInNumber) => {
  return getCurrentMoment(date).weekday(dayofWeekInNumber);
};

export const getCurrentDayOfWeek = date => {
  const dayOfWeekInNumber = getCurrentMoment(date).weekday();
  const dayOfWeekLabel = constructConstantLabel(DAYS_OF_WEEK, dayOfWeekInNumber, { isConstantObject: true, codeKey: 'value' });
  return { dayOfWeekInNumber, dayOfWeekLabel };
};

// ======================================== Checking
export const checkIsMoment = data => {
  const dataInMoment = moment(data);
  return checkIsString(data) && dataInMoment.isValid();
};

export const checkIsDate = (type, date1, date2, canOverlap) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  const typeInLowerCase = type.toLowerCase();

  const checkIsDateSame = () => date1Moment.isSame(date2Moment);
  const checkIsDateBefore = () => date1Moment.isBefore(date2Moment);
  const checkIsDateAfter = () => date1Moment.isAfter(date2Moment);

  switch (typeInLowerCase) {
    case 'same':
      return checkIsDateSame();

    case 'before':
      return checkIsDateBefore() || (canOverlap && checkIsDateSame());

    case 'after':
      return checkIsDateAfter() || (canOverlap && checkIsDateSame());

    default:
      return false;
  }
};

export const checkIsDateInBetween = (currentDate, startDate, endDate, overlapStart, overlapEnd) => {
  return checkIsDate('after', currentDate, startDate, overlapStart) && checkIsDate('before', currentDate, endDate, overlapEnd);
};

// ======================================== Get Difference
export const getDifferenceBetweenDate = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'day');
};
