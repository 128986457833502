import { useCustomQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const useGetStoreInfo = ({ postProcessFunc, onError } = {}) => {
  const getStoreInfo = async () => {
    return api.get('/store');
  };

  return useCustomQuery('storeInfo', [], getStoreInfo, {
    postProcessFunc,
    onError
  });
};
