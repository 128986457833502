import i18n from 'i18next';
import Backend from 'i18next-fluent-backend';
import Fluent from 'i18next-fluent';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';

import { initReactI18next } from 'react-i18next';

import { REACT_APP_IS_ENABLE_TRANS_DEBUG } from 'config/env';

/* ================================================ Language Configuration ================================================ */
const ALL_TRANSLATIONS_FILES = [
  'common',
  'compOutOfStockLabel',
  'compProductCard',
  'contextCart',
  'layoutMain',
  'modalProductDetails',
  'pageCart',
  'pageHome',
  'pageLanding',
  'pagePrivacyPolicy',
  'pageRefundPolicy',
  'pageTermsOfService'
];

export const SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'EN', isDefault: true },
  { value: 'zh', label: '中文' }
];

export const useLoadAllTranslationFiles = () => {
  useTranslation(ALL_TRANSLATIONS_FILES);
};

/* ================================================ Main instance ================================================ */
const SUPPORTED_LANGUAGES_ENUM = SUPPORTED_LANGUAGES.map(lng => lng.value);
const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.find(lng => lng.isDefault);

i18n
  .use(Fluent)
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES_ENUM,
    debug: REACT_APP_IS_ENABLE_TRANS_DEBUG,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    customHeaders: () => ({
      'Cache-Control': 'no-cache'
    }),
    react: {}
  });

export default i18n;
