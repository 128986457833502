import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import PriceDisplay from 'components/_local/PriceDisplay/PriceDisplay';
import ImagePlaceholder from 'images/no-image-placeholder.jpg';

import { encodeImageUrl } from 'utils/general';

import {
  CardBodyRow,
  CoverPhoto,
  ProductAvatar,
  ProductLabel,
  KeywordLabel,
  OriPriceLabel,
  PriceAndQuantityContainer,
  PriceLabel,
  StyledCard,
  ProductTag,
  InfoContainer
} from './ProductCard.styles';

const ProductPriceDisplay = ({ isRange, minPrice, maxPrice }) => {
  return isRange ? (
    <>
      <PriceDisplay amount={minPrice} />
      <span> - </span>
      <PriceDisplay amount={maxPrice} />
    </>
  ) : (
    <PriceDisplay amount={minPrice} />
  );
};

const ProductCard = ({
  coverPhotoUrl,
  name,
  keyword,
  priceMax,
  priceMin,
  oriPriceMin,
  oriPriceMax,
  onClick,
  isActive = true,
  hasStock,
  quantityLeft,
  isLiveSelling,
  cardColor
}) => {
  const { t } = useTranslation(['compProductCard']);
  const hasPriceRange = priceMax !== priceMin;
  const hasOriPriceRange = oriPriceMax !== oriPriceMin;

  return (
    <StyledCard
      isActive={isActive}
      hoverable={isActive && !!onClick}
      cover={
        !isLiveSelling && (
          <Badge.Ribbon text={t('compProductCard:out-of-stock-label')} color="#9b9b9b" style={{ display: hasStock ? 'none' : 'auto' }}>
            <CoverPhoto src={encodeImageUrl(coverPhotoUrl || ImagePlaceholder)} />
          </Badge.Ribbon>
        )
      }
      styles={{ body: { padding: 0 } }}
      isLiveSelling={isLiveSelling}
      cardColor={cardColor}
      onClick={isActive ? onClick : null}
    >
      <CardBodyRow gutter={8} wrap={false} isLiveSelling={isLiveSelling} align="middle">
        {isLiveSelling && (
          <Col>
            <ProductAvatar shape="square" src={encodeImageUrl(coverPhotoUrl || ImagePlaceholder)}></ProductAvatar>
          </Col>
        )}
        <Col flex="auto" style={{ height: '100%' }}>
          <InfoContainer>
            <div>
              <ProductLabel hasOriPrice={!!oriPriceMin} isLiveSelling={isLiveSelling} ellipsis={{ rows: 2 }}>
                {name}
              </ProductLabel>
              <KeywordLabel hasOriPrice={!!oriPriceMin} isLiveSelling={isLiveSelling} ellipsis={{ rows: 1 }}>
                {keyword}
              </KeywordLabel>
            </div>
            <PriceAndQuantityContainer>
              <div style={{ textAlign: 'center', width: '100%' }}>
                <PriceLabel hasOriPrice={!!oriPriceMin} ellipsis={{ rows: 2 }}>
                  <ProductPriceDisplay isRange={hasPriceRange} minPrice={priceMin} maxPrice={priceMax} />
                </PriceLabel>
                {!!oriPriceMin && (
                  <OriPriceLabel ellipsis={{ rows: 2 }}>
                    <ProductPriceDisplay isRange={hasOriPriceRange} minPrice={oriPriceMin} maxPrice={oriPriceMax} />
                  </OriPriceLabel>
                )}
              </div>
              {isActive ? (
                <div>
                  {isLiveSelling && quantityLeft < 10 && (
                    <ProductTag highlight={hasStock}>
                      {hasStock ? t('compProductCard:inventory-left-label', { inventory: quantityLeft }) : t('compProductCard:out-of-stock-label')}
                    </ProductTag>
                  )}
                </div>
              ) : (
                <div>
                  <ProductTag>{t('compProductCard:inactive-label')}</ProductTag>
                </div>
              )}
            </PriceAndQuantityContainer>
          </InfoContainer>
        </Col>
      </CardBodyRow>
    </StyledCard>
  );
};

export default ProductCard;
