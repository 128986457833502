import { QueryClient as ReactQueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query';
import { MS_IN_SEC } from 'utils/constants';

/* ================================================ Exported Functions ================================================ */
// ================================================ Main Component
const queryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 2 * MS_IN_SEC
    }
  }
});

export default queryClient;

// ================================================ Others Components
export const QueryClientProvider = ({ children }) => {
  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};
