import PropTypes from 'prop-types';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';
import { numberWithCommas, checkHasValue } from 'utils/general';

const constructDisplayPrice = ({ amount, currencySymbol = 'RM', isHideSymbol, isDeduct, labelWhenUndefined, labelWhenZero }) => {
  if (labelWhenUndefined) {
    if (!checkHasValue(amount)) {
      return labelWhenUndefined;
    }
  }

  if (labelWhenZero) {
    if (amount === 0) {
      return labelWhenZero;
    }
  }

  const displayFee = String(numberWithCommas(amount));
  const displayFeeWithSymbol = !isHideSymbol && currencySymbol ? `${currencySymbol}${displayFee}` : displayFee;

  if (isDeduct) {
    return `- ${displayFeeWithSymbol}`;
  }

  return displayFeeWithSymbol;
};

const PriceDisplay = ({
  amount,
  isHideSymbol = false,
  isDeduct = false,
  prefix = '',
  suffix = '',
  labelWhenUndefined,
  labelWhenZero,
  propsContextRoot,
  className
}) => {
  const { storeInfo } = propsContextRoot;
  const { currencySymbol } = storeInfo;
  return (
    <span className={className}>
      {prefix}
      {constructDisplayPrice({ amount, currencySymbol, isHideSymbol, isDeduct, labelWhenUndefined, labelWhenZero })}
      {suffix}
    </span>
  );
};

PriceDisplay.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  isHideSymbol: PropTypes.bool,
  isDeduct: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  labelWhenUndefined: PropTypes.string,
  labelWhenZero: PropTypes.string
};

export default withContextRoot(PriceDisplay);
