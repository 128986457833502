import styled from '@emotion/styled';
import { Avatar } from 'antd';

export const StyledAvatar = styled(Avatar)`
  background-color: ${props => (!props.src ? props.theme.color.primary : undefined)};
  box-shadow: ${props => (!props.isHideShadow ? `0px 3px 8px ${!props.src ? props.theme.color.primary : props.theme.color.tertiary}33` : undefined)};
  color: ${props => props.theme.color.tertiary};
  font-size: 20px;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.fontFamily.title};
  cursor: ${props => (props.onClick ? 'pointer' : undefined)};
`;
