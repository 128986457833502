import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';

import { guard } from 'utils/general';

const { Option } = AntdSelect;

const Selection = ({
  className,
  value,
  options,
  placeholder,
  mode = 'default',

  isAllowClear = true,
  isAllowFilter = true,
  isDisabled = false,

  dropdownStyle,

  onChange = () => {},
  onSearch,
  updateFormValue = () => {}
}) => {
  const searchModeProps =
    isAllowFilter || onSearch
      ? {
          showSearch: true,
          onSearch,
          filterOption: (input, option) => guard(() => option.children.toLowerCase().indexOf(input.toLowerCase()), -1) >= 0
        }
      : {};

  const handleOnChange = value => {
    onChange(value);
    updateFormValue(value);
  };

  return (
    <AntdSelect
      className={className}
      style={{ width: '100%' }}
      value={value}
      mode={mode}
      optionFilterProp="children"
      placeholder={placeholder}
      {...searchModeProps}
      allowClear={isAllowClear}
      disabled={isDisabled}
      dropdownStyle={dropdownStyle}
      onChange={handleOnChange}
    >
      {options.map(option => (
        <Option key={option.value || option} value={option.value}>
          {option.label || option.value || option}
        </Option>
      ))}
    </AntdSelect>
  );
};

Selection.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  mode: PropTypes.oneOf(['multiple', 'tags', 'default']),

  isAllowClear: PropTypes.bool,
  isAllowFilter: PropTypes.bool,
  isDisabled: PropTypes.bool,

  dropdownStyle: PropTypes.object,

  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  updateFormValue: PropTypes.func
};

export default Selection;
