import styled from '@emotion/styled';

export const TermsContainer = styled.div`
  padding: 16px 0;
`;

export const TermsHeader = styled.h1``;

export const TermsText = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
