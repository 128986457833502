import { useCustomQuery } from 'hooks/reactQuery';

import api from './apiHelper';

export const useGetPastLivePosts = (isAllowMsLiveVideo, onError) => {
  const getPastLivePosts = async () => {
    return api.get(`/post/live-ended`);
  };
  return useCustomQuery('pastLivePosts', [isAllowMsLiveVideo], getPastLivePosts, {
    onError,
    enabled: !!isAllowMsLiveVideo
  });
};
