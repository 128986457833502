import { useState } from 'react';
import propTypes from 'prop-types';
import { Image } from 'antd';

import { encodeImageUrl } from 'utils/general';

const ImagesPreview = ({ className, thumbnailImageUrl, previewImageUrl, imageUrls = [], shouldIncludePreviewImage = true, height, width }) => {
  const [isVisible, setIsVisible] = useState(false);

  const previewImageUrls = shouldIncludePreviewImage ? [previewImageUrl, ...imageUrls] : imageUrls;

  return (
    <>
      <Image
        className={className}
        src={encodeImageUrl(thumbnailImageUrl)}
        height={height}
        width={width}
        preview={{ visible: false }}
        onClick={() => {
          setIsVisible(true);
        }}
      />
      {previewImageUrls.length > 0 && (
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup preview={{ visible: isVisible, onVisibleChange: newVisible => setIsVisible(newVisible) }}>
            {previewImageUrls.map(imageUrl => {
              return <Image key={imageUrl} src={encodeImageUrl(imageUrl)} />;
            })}
          </Image.PreviewGroup>
        </div>
      )}
    </>
  );
};

ImagesPreview.propTypes = {
  className: propTypes.string,

  thumbnailImageUrl: propTypes.string.isRequired,
  previewImageUrl: propTypes.string.isRequired,
  imageUrls: propTypes.array,

  shouldIncludePreviewImage: propTypes.bool,

  height: propTypes.number,
  width: propTypes.number
};

export default ImagesPreview;
