import { useCallback } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import { StyledInput, StyledSelection } from './Contact.styles';

const Contact = ({
  value = { countryCode: undefined, contactNumber: undefined },
  options = [],

  isDisabled = false,

  onChange = () => {},
  updateFormValue = () => {},

  extraPropsInput = {},
  extraPropsSelection = {}
}) => {
  const countryCodeValue = value.countryCode;
  const contactNumberValue = value.contactNumber;

  const handleOnChangeCountryCode = useCallback(
    countryCode => {
      const formattedValue = { countryCode, contactNumber: contactNumberValue };
      onChange(formattedValue);
      updateFormValue(formattedValue);
    },
    [contactNumberValue, onChange, updateFormValue]
  );

  const handleOnChangeContactNumber = useCallback(
    contactNumber => {
      const formattedValue = { countryCode: countryCodeValue, contactNumber };
      onChange(formattedValue);
      updateFormValue(formattedValue);
    },
    [countryCodeValue, onChange, updateFormValue]
  );

  return (
    <Row>
      <Col span={6}>
        <StyledSelection
          value={countryCodeValue}
          options={options}
          isDisabled={isDisabled}
          onChange={handleOnChangeCountryCode}
          {...extraPropsSelection}
        />
      </Col>
      <Col span={18}>
        <StyledInput value={contactNumberValue} isDisabled={isDisabled} onChange={handleOnChangeContactNumber} {...extraPropsInput} />
      </Col>
    </Row>
  );
};

Contact.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,

  isDisabled: PropTypes.bool,

  onChange: PropTypes.func,
  updateFormValue: PropTypes.func,

  extraPropsInput: PropTypes.object,
  extraPropsSelection: PropTypes.object
};

export default Contact;
